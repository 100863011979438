import { ReactNode } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "./Dialog";
import { InfoIcon } from "movestic-core/icons/InfoIcon";

export const InfoDialog = ({
    title,
    children,
    trigger,
}: {
    trigger?: ReactNode;
    title: string;
    children: ReactNode;
}) => (
    <Dialog>
        <DialogTrigger>{trigger || <InfoIcon size={"14"} className="mx-2" />}</DialogTrigger>
        <DialogContent>
            <DialogHeader>
                <DialogTitle>{title}</DialogTitle>
            </DialogHeader>
            {children}
        </DialogContent>
    </Dialog>
);
