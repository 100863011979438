import * as React from "react";
import { QuoteComponent as QuoteProps } from "movestic-blocks/components/__generated__/graphql";
import { Q } from "movestic-core/tailwind/Texts";
import { QuoteIcon } from "movestic-core/icons/QuoteIcon";

export const Quote: React.FunctionComponent<QuoteProps> = ({ text }) => (
    <div className="flex my-6">
        <QuoteIcon />
        <Q className="ml-3">{text}</Q>
    </div>
);
