export const BulletIcon = ({
    color = "currentColor",
    size = "24",
    className,
    style,
}: {
    color?: string;
    size?: string | number;
    className?: string;
    style?: React.CSSProperties;
}) => (
    <div className={`${className}`}>
        <svg
            width={size}
            height={size}
            style={style}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="4" fill={color} />
        </svg>
    </div>
);
