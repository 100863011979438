export const HappyFaceIcon = ({
    size = "22",
    color = "#D5004B",
}: {
    size?: string | number;
    color?: string;
    style?: React.CSSProperties;
}) => (
    <svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.4272 44.4272C40.0394 48.8147 34.2055 51.2314 28 51.2314C21.7947 51.2314 15.9609 48.815 11.5731 44.4272C7.18535 40.0394 4.76867 34.2055 4.76867 28C4.76867 21.7945 7.18516 15.9607 11.5731 11.573C15.9609 7.18528 21.7947 4.7686 28 4.7686C34.2055 4.7686 40.0394 7.18505 44.4272 11.573C48.815 15.9607 51.2317 21.7945 51.2317 28C51.2317 34.2055 48.8153 40.0394 44.4272 44.4272ZM45.8195 10.1811C41.0594 5.42128 34.7315 2.80005 28 2.80005C21.2687 2.80005 14.9406 5.42128 10.1811 10.1811C5.42128 14.9406 2.80005 21.2691 2.80005 28C2.80005 34.731 5.42128 41.0594 10.1811 45.819C14.9406 50.5787 21.2691 53.2 28 53.2C34.731 53.2 41.0594 50.5787 45.8195 45.819C50.5787 41.0594 53.2 34.731 53.2 28C53.2 21.2691 50.5787 14.9406 45.8195 10.1811Z"
            fill={color}
        />
        <path
            d="M28.0391 40.4905C32.9993 40.4905 36.0013 38.4984 37.6462 36.8277C39.4477 34.9973 40.0555 33.1464 40.0805 33.0685C40.2466 32.5509 39.9612 31.9965 39.4437 31.8305C39.3438 31.7984 39.2426 31.7833 39.1428 31.7833C38.7264 31.7833 38.3399 32.0495 38.2058 32.4672C38.202 32.4786 37.6834 34.012 36.1775 35.5124C34.1734 37.5093 31.4351 38.5215 28.0391 38.5215C24.643 38.5215 21.905 37.5093 19.9008 35.5124C18.3945 34.012 17.8761 32.4786 17.8711 32.4632C17.7011 31.9487 17.1464 31.6661 16.6311 31.834C16.1156 32.0019 15.8322 32.5525 15.9976 33.0685C16.0226 33.1464 16.6302 34.9973 18.4319 36.8277C20.0766 38.4984 23.0788 40.4905 28.0391 40.4905Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.4218 24.942L33.4204 24.9443ZM40.0796 24.9443L40.0744 24.936C40.0684 24.9263 40.0574 24.909 40.0415 24.8851C40.0095 24.8371 39.9581 24.7635 39.8874 24.6726C39.7452 24.4898 39.5297 24.2434 39.243 23.9976C38.6701 23.5065 37.8436 23.0416 36.75 23.0416C35.6563 23.0416 34.8299 23.5065 34.2569 23.9976C33.9702 24.2434 33.7547 24.4898 33.6125 24.6726C33.5418 24.7635 33.4904 24.8371 33.4585 24.8851C33.4426 24.909 33.4316 24.9263 33.4255 24.936L33.4204 24.9443C33.1759 25.3589 32.642 25.4985 32.2258 25.2558C31.8083 25.0123 31.6673 24.4765 31.9108 24.0591L32.6666 24.5C31.9108 24.0591 31.9108 24.0591 31.9108 24.0591L31.9117 24.0577L31.9126 24.0561L31.9149 24.0522L31.921 24.0419L31.9397 24.0115C31.9549 23.987 31.9758 23.9542 32.0024 23.9143C32.0555 23.8347 32.1317 23.726 32.2312 23.5982C32.4293 23.3435 32.7242 23.0065 33.118 22.6689C33.9062 21.9934 35.1214 21.2916 36.75 21.2916C38.3786 21.2916 39.5938 21.9934 40.3819 22.6689C40.7758 23.0065 41.0707 23.3435 41.2688 23.5982C41.3682 23.726 41.4444 23.8347 41.4976 23.9143C41.5241 23.9542 41.5451 23.987 41.5603 24.0115L41.5789 24.0419L41.5851 24.0522L41.5874 24.0561L41.5883 24.0577C41.5883 24.0577 41.5891 24.0591 40.8333 24.5L41.5891 24.0591C41.8326 24.4765 41.6916 25.0123 41.2742 25.2558C40.858 25.4985 40.3241 25.3589 40.0796 24.9443Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.9204 24.9443L15.9218 24.942L15.9204 24.9443ZM22.5796 24.9443L22.5744 24.936C22.5684 24.9263 22.5574 24.909 22.5415 24.8851C22.5095 24.8371 22.4581 24.7635 22.3874 24.6726C22.2452 24.4898 22.0297 24.2434 21.743 23.9976C21.1701 23.5065 20.3436 23.0416 19.25 23.0416C18.1563 23.0416 17.3299 23.5065 16.7569 23.9976C16.4702 24.2434 16.2547 24.4898 16.1125 24.6726C16.0418 24.7635 15.9904 24.8371 15.9585 24.8851C15.9426 24.909 15.9316 24.9263 15.9255 24.936L15.9204 24.9443C15.6759 25.3589 15.142 25.4985 14.7258 25.2558C14.3083 25.0123 14.1673 24.4765 14.4108 24.0591L15.1666 24.5C14.4108 24.0591 14.4108 24.0591 14.4108 24.0591L14.4117 24.0577L14.4126 24.0561L14.4149 24.0522L14.421 24.0419L14.4397 24.0115C14.4549 23.987 14.4758 23.9542 14.5024 23.9143C14.5555 23.8347 14.6317 23.726 14.7312 23.5982C14.9293 23.3435 15.2242 23.0065 15.618 22.6689C16.4062 21.9934 17.6214 21.2916 19.25 21.2916C20.8786 21.2916 22.0938 21.9934 22.8819 22.6689C23.2758 23.0065 23.5707 23.3435 23.7688 23.5982C23.8682 23.726 23.9444 23.8347 23.9976 23.9143C24.0241 23.9542 24.0451 23.987 24.0603 24.0115L24.0789 24.0419L24.0851 24.0522L24.0874 24.0561L24.0883 24.0577C24.0883 24.0577 24.0891 24.0591 23.3333 24.5L24.0891 24.0591C24.3326 24.4765 24.1916 25.0123 23.7742 25.2558C23.358 25.4985 22.8241 25.3589 22.5796 24.9443Z"
            fill={color}
        />
    </svg>
);
