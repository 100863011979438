"use client";
import {
    ListComponent as ListModel,
} from "movestic-blocks/components/__generated__/graphql";
import mainTheme from "movestic-core/main.theme";
import { ReactNode } from "react";
import { BulletIcon } from "movestic-core/icons/BulletIcon";
import { CheckWithCircleIcon } from "movestic-core/icons/CheckWithCircleIcon";

export const ListBlock = ({ listIcon, listItems }: ListModel) => {
    let bullet = null;
    switch (listIcon) {
        case "blackBullet":
            bullet = bullet = <BulletIcon color={mainTheme.color.lightBlack} />;
            break;
        case "coloredCheck":
            bullet = bullet = <CheckWithCircleIcon className="text-cherryDark" />;
            break;
        default:
            bullet = <BulletIcon className="text-cherryDark" />;
            break;
    }

    return (
        <ul>
            {listItems?.map((item, index) => {
                return (
                    <li className={"flex gap-2 list-none pb-2 "} key={`li_${item.text}_${index}`}>
                        {bullet && <div>{bullet}</div>}
                        {item.text}
                    </li>
                );
            })}
        </ul>
    );
};

export const OrderedListBlock = ({ children }: { children: ReactNode }) => {
    return (
        <ol className="[&_li]:mb-2 ml-6 list-decimal marker:text-cherryDark marker:font-bold marker:mr-8">
            {children}
        </ol>
    );
};
