export const NewPageIcon = ({
    size = "18",
    color = "currentColor",
    style,
    className,
}: {
    size?: string | number;
    color?: string;
    style?: React.CSSProperties;
    className?: string;
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
        className={className}
    >
        <path
            d="M12.5 20H0V7.5H5V0H20V15H12.5V20ZM11.25 18.75V15H5V8.75H1.25V18.75H11.25ZM6.25 13.75H18.75V1.25H6.25V13.75ZM12.5 2.43333H17.5V7.43333H16.25V4.56667L12.9167 7.9L12.0667 7L15.4 3.66667H12.5V2.43333Z"
            fill={color}
        />
    </svg>
);
