const imagePath = `/static`;

const images = {
    logo: `${imagePath}/images/movestic-logo.svg`,
    backgroundLogo: `${imagePath}/images/m-logo-background.svg`,
    backgroundLogoWhite: `${imagePath}/images/m-logo-background-white.svg`,
    logoMinimal: `${imagePath}/images/movestic-logo-minimal.svg`,
    upcomming: `${imagePath}/images/background-images/modal-image-left_tall.jpg`,
    heroImage: `${imagePath}/images/kvinna_haller_mobil.jpg`,
    maiaLogo: `${imagePath}/images/MAIA_Logo.svg`,
    preferences: `${imagePath}/images/preferences.png`,
    offer: `${imagePath}/images/offer.png`,
    movesticLogo: `${imagePath}/images/movestic-logo.svg`,
    demoImage: `${imagePath}/images/demo.png`,
    swesif: `${imagePath}/images/swesif-logo-16x16.gif`,
    nordicEcoLabel: `${imagePath}/images/nordicEcoLabel.svg`,
    mobileLogin: `${imagePath}/images/mobile_login.png`,
    fullyWorking: `${imagePath}/images/fullyworking.png`,
    notFullyWorking: `${imagePath}/images/notfullyworking.png`,
};

export { images };
