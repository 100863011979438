export const InfoIcon = ({
    size = "22",
    color = "currentColor",
    className,
    style,
}: {
    size?: string | number;
    color?: string;
    className?: string;
    style?: React.CSSProperties;
}) => (
    <svg
        width={size}
        height={size}
        style={style}
        viewBox="0 0 20 20"
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.5906 5.9776C10.8265 5.97859 11.0555 5.89762 11.2384 5.7485C11.4213 5.59939 11.5467 5.3914 11.5932 5.16008C11.6397 4.92875 11.6045 4.68845 11.4935 4.48023C11.3825 4.27201 11.2026 4.1088 10.9846 4.01849C10.7666 3.92818 10.524 3.91637 10.2983 3.98509C10.0726 4.0538 9.87772 4.19877 9.74702 4.39523C9.61633 4.59169 9.55791 4.82743 9.58175 5.06218C9.6056 5.29693 9.71022 5.51612 9.87775 5.68228C10.0674 5.87041 10.3234 5.97647 10.5906 5.9776Z"
            fill={color}
        />
        <path
            d="M11.2016 13.7576L10.1833 14.2363L11.2627 8.45214C11.2922 8.3212 11.2829 8.18452 11.2361 8.05874C11.1893 7.93295 11.1069 7.82349 10.999 7.74365C10.8912 7.66382 10.7624 7.61705 10.6284 7.60905C10.4944 7.60105 10.361 7.63215 10.2444 7.69858L8.20775 8.84929C8.1286 8.89182 8.05878 8.94978 8.00242 9.01976C7.94606 9.08974 7.9043 9.17031 7.87962 9.2567C7.85494 9.3431 7.84782 9.43356 7.85871 9.52275C7.86959 9.61195 7.89825 9.69805 7.94299 9.77597C7.98651 9.85547 8.04545 9.9255 8.11634 9.98197C8.18723 10.0384 8.26866 10.0802 8.35588 10.1049C8.4431 10.1295 8.53436 10.1365 8.62432 10.1255C8.71428 10.1145 8.80115 10.0857 8.87985 10.0407L9.61305 9.63341L8.52343 15.275C8.49733 15.3982 8.50586 15.5263 8.54807 15.645C8.59029 15.7637 8.66454 15.8683 8.7626 15.9474C8.86067 16.0265 8.97871 16.0769 9.10366 16.093C9.2286 16.1091 9.35556 16.0903 9.47048 16.0387L11.7108 15.0204C11.8776 14.9467 12.0085 14.8101 12.0753 14.6405C12.142 14.4708 12.1391 14.2816 12.0672 14.1141C11.9976 13.9538 11.8683 13.8269 11.7067 13.7604C11.5451 13.6938 11.3639 13.6929 11.2016 13.7576Z"
            fill={color}
        />
        <path
            d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433281 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9946 7.34949 18.9393 4.80907 17.0651 2.93488C15.1909 1.06068 12.6505 0.00538176 10 0V0ZM10 18.4114C8.33638 18.4114 6.71013 17.9181 5.32688 16.9938C3.94363 16.0696 2.86552 14.7559 2.22888 13.2189C1.59224 11.6819 1.42567 9.99067 1.75022 8.35901C2.07478 6.72736 2.87589 5.22859 4.05224 4.05224C5.2286 2.87588 6.72737 2.07477 8.35902 1.75022C9.99067 1.42566 11.6819 1.59224 13.2189 2.22887C14.7559 2.86551 16.0696 3.94362 16.9938 5.32687C17.9181 6.71012 18.4114 8.33638 18.4114 10C18.4114 12.2308 17.5252 14.3703 15.9478 15.9478C14.3703 17.5252 12.2308 18.4114 10 18.4114Z"
            fill={color}
        />
    </svg>
);
