export const EmbedMap = () => (
    <div>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d440.2428793499715!2d18.064375659843993!3d59.340579899838914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9d6848154def%3A0x4009ba833337c3a9!2sMovestic%20Liv%20%26%20Pension!5e0!3m2!1sen!2sse!4v1711453365764!5m2!1sen!2sse"
            width="600"
            height="450"
            style={{ border: 0, width: "100%" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
    </div>
);
